<template>
   <!--新的团队列表  -->
   <a-card :bordered='false' style="min-height:80vh">
      <a-row class="query-row" type='flex' :gutter="12">
        <a-col style="width:240px">
           <span>用户手机号：</span>
           <a-input :maxLength='11' v-model="queryData.mobile" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:200px">
           <span>用户ID：</span>
           <a-input v-model="queryData.unionId" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col>
           <a-button @click="() => {this.loadTableData();}" type='primary'>查询</a-button>
        </a-col>
      </a-row>
      <template v-if="userInfo.unionId">
         <a-spin :spinning="spinning">
            <a-card :bordered="false" hoverable>
               <header slot="title" class="tc-header">当前用户信息</header>
               <a-row>
                  <a-col class="userinfo" :span='12'><span>用户ID</span>{{userInfo.unionId}}</a-col>
                  <a-col class="userinfo" :span='12'><span>用户手机号</span>{{userInfo.mobile}}</a-col>
                  <a-col class="userinfo" :span='12'><span>用户等级</span>{{userLevelStr(userInfo.userLevel)}}</a-col>
                  <a-col class="userinfo" :span='12'><span>团队奖励积分</span>{{userInfo.teamRewardJifen}}</a-col>
               </a-row>
            </a-card>
            <a-card :bordered="false" hoverable style="margin-top:6px">
               <header slot="title" class="tc-header">当前团队情况</header>
               <a-table :columns="columns" :dataSource="tableData" :pagination="false"
                  :loading="loading"
                  :scroll="{x: true}"
                  size="default">
               </a-table>
            </a-card>
         </a-spin>
      </template>

      <a-modal  v-model="teamModal" :title="modalTitle" :footer="null" destroyOnClose width='800px'>
         <team-modal :userId='userInfo.userId' :userLevel='userLevel' :activeStatus='activeStatus'></team-modal>
      </a-modal>
   </a-card>
</template>

<script>
import api from '@/api'
import teamModal from './components/teamListModal.vue'
export default {
   name:'TeamList',
   components: {teamModal},
   data () {
      return {
         loading:false,
         spinning:false,
         teamModal:false,
         queryData: {
            mobile: undefined,
            unionId: undefined,
         },
         columns:[
            {
               title:'',
               align: 'center',
               ellipsis:true,
               dataIndex: 'title',
               width:'180px'
            },
            {
               title: () => {
                  return (
                     <div class='column-title'>
                        <span>已激活</span>
                        <a-tooltip title='当前团队已达到激活门槛及点亮身份的总人数'>
                           <a-icon type="exclamation-circle" />
                        </a-tooltip>
                     </div>
                  )
               },
               align: 'center',
               ellipsis:true,
               customRender: (text, record, index) => {
                  let {activeNum} = record
                  return <a onClick={() => {this.handleClick({activeStatus:1,...record})}}>{activeNum}</a>
               }
            },
            {
               title:() => {
                  return (
                     <div class='column-title'>
                        <span>准激活</span>
                        <a-tooltip title='当前团队已达到激活门槛但未点亮身份人数'>
                           <a-icon type="exclamation-circle" />
                        </a-tooltip>
                     </div>
                  )
               },
               align: 'center',
               ellipsis:true,
               customRender: (text, record, index) => {
                  let {prepareActiveNum} = record
                  return <a onClick={() => {this.handleClick({activeStatus:2,...record})}}>{prepareActiveNum}</a>
               }
            },
            {
               title: () => {
                  return (
                     <div class='column-title'>
                        <span>未激活</span>
                        <a-tooltip title='当前团队未达到激活门槛及未点亮身份人数'>
                           <a-icon type="exclamation-circle" />
                        </a-tooltip>
                     </div>
                  )
               },
               align: 'center',
               ellipsis:true,
               customRender: (text, record, index) => {
                  let {unActiveNum} = record
                  return <a onClick={() => {this.handleClick({activeStatus:0,...record})}}>{unActiveNum}</a>
               }
            }
         ],
         tableData:[
            {
               title: '直属小汇同学',
               key: 'directXHTX',
               userLevel:1,
            },
            {
               title: '直属小汇推介官',
               userLevel:2,
               key: 'directXHTJG'
            },
            {
               title: '直属小汇俱乐部',
               userLevel:3,
               key: 'directXHJLB'
            },
            {
               title: '直属小汇服务商',
               userLevel:4,
               key: 'directXHFWS'
            },
            {
               title: '团队小汇同学',
               userLevel:5,
               key: 'teamXHTX'
            },
            {
               title: '团队小汇推介官',
               userLevel:6,
               key: 'teamXHTJG'
            },
            {
               title: '团队小汇俱乐部',
               userLevel:7,
               key: 'teamXHJLB'
            },
            {
               title: '团队小汇服务商',
               userLevel:8,
               key: 'teamXHFWS'
            },
         ],
         userLevel: undefined,
         activeStatus: undefined,
         userInfo: {},
         teamInfo: {},
         modalTitle: ''
      }
   },
   methods: {
      loadTableData () {
         let {mobile, unionId} = this.queryData
         if (!mobile && !unionId) return 
         this.spinning = true
         let p = {
            mobile: mobile || undefined,
            unionId: unionId || undefined,
         }
         api.shopkeeperManage.teamMember(p).then(res => {
            if (res.code === '00000') {
               this.userInfo = res.data.userInfo
               this.tableData = this.tableData.map(i => {
                  return {
                     ...i,
                     ...res.data.teamInfo[i.key]
                  }
               })
            } else {
               this.$message.error(res.msg)
            }
         }).finally(() => {
            this.spinning = false
         })
      },
      handleClick (record) {
         let {userLevel,activeStatus,title} = record
         if (!this.userInfo.userId) return 
         this.teamModal = true
         this.userLevel = userLevel
         this.activeStatus = activeStatus
         this.modalTitle = `【${this.userInfo.mobile}】${title}`
      },
      userLevelStr (userLevel) {
         let text = ['小汇同学', '小汇推介官', '小汇俱乐部', '小汇服务商']
         return text[userLevel]
      }
   }
}
</script>

<style lang="less" scoped>
/deep/.tc-header{
   font-size: 18px;
   font-weight: 550;
}
/deep/.ant-card-hoverable{
   cursor: auto;
}
.userinfo {
   & > span {
      display: inline-block;
      font-weight: 550;
      margin-right: 12px;
   }
}
.column-title {
   & > span {
      margin-right: 4px;
   }
}
</style>
