<template>
   <div>
      <a-table :columns="columns" :dataSource="tableData" :pagination="false"
             :rowKey="record => record.unionId "
             :loading="loading"
             :scroll="{x: true}"
             style="max-height:60vh;overflow: auto;"
             size="default">
      </a-table>
      <a-pagination
         style="display: flex; flex-direction: row; justify-content: flex-end; margin:10px 10px 5px 0"
         size="small"
         v-model="pageNum"
         :total="total"
         :showTotal="total => `共 ${total} 条`"
         showLessItems
         showSizeChanger
         :pageSize.sync="pageSize"
         @change="loadTableData"
         @showSizeChange="() => {this.pageNum = 1; this.loadTableData()}"
      />
   </div>
</template>

<script>
import api from '@/api'
export default {
   props: {
      userId: {
         type: String,
         required: true
      },
      userLevel: {
         type: [String, Number],
         required: true
      },
      activeStatus: {
         type: [String, Number],
         required: true
      }
   },
   data () {
      return {
         loading:false,
         pageNum:1,
         total:0,
         pageSize:10,
         columns:[
            {
               title: '序号',
               align: 'center',
               ellipsis:true,
               customRender: (text, record, index) => {
                  return index + 1
               }
            },
            {
               title: '用户ID',
               dataIndex: 'unionId',
               align: 'center',
               ellipsis:true
            },
            {
               title: '用户手机号',
               dataIndex: 'mobile',
               align: 'center',
               ellipsis:true
            },
            {
               title: '用户等级',
               dataIndex: 'userLevel',
               align: 'center',
               ellipsis:true,
               customRender: (text,record) => {
                  return ['小汇同学','小汇推介官','小汇俱乐部','小汇服务商'][text] || ''
               }
            },
            {
               title: '激活状态',
               dataIndex: 'activeStatus',
               align: 'center',
               ellipsis:true,
               customRender: (text,record) => {
                  return ['未激活','已激活', '准激活'][text] || ''
               }
            }
         ],
         tableData:[],
      }
   },
   created () {
      this.loadTableData()
   },
   methods: {
      loadTableData () {
         let {userId, userLevel, activeStatus} = this.$props
         if (!userId) return 
         let p = {
            userId, userLevel, activeStatus,
            pageNum: this.pageNum,
            pageSize: this.pageSize
         }
         this.loading = true
         api.shopkeeperManage.teamMemberList(p).then(res => {
            if (res.code === '00000') {
               this.tableData = res.data.dataList
               this.total = res.data.total
            } else {
               this.$message.error(res.msg)
            }
         }).finally(() => this.loading = false)
      }
   }
}
</script>

<style>
</style>